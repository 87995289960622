<template>
  <table class="tracks">
    <draggable v-model="tracks" :disabled="!editable" @change="reorder">
      <tr class="track" :class="{ 'active': index == curr_index }" v-for="(track, index) in tracks" :key="index + '-' + track.id" @mouseenter="enter" @mouseleave="leave">
        <td class="index text-right">{{ index+1 }}.</td>
        <td class="title" @click="play(index)">{{ track.title }}</td>
        <td class="delete" v-if="editable"><span @click="remove(index)">❌</span></td>
      </tr>
    </draggable>
  </table>
</template>

<script>

import draggable from 'vuedraggable'

export default {
  components: { draggable, },
  props: [ 'playlist' ],
  data: function() {
    return {
      curr_index: null,
    }
  },
  computed: {
    editable() {
      return this.playlist?.editable
    },
    tracks: {
      get() {
        return this.playlist?.tracks
      },
      set(_) {
        // player is notified via @change
        // and will send an audio:status event
        // with updated playlist
      },
    }
  },
  mounted: function() {
    this.$audio.on('status', (status) => {
      this.curr_index = status?.playing ? status?.index : null
    })
  },
  methods: {
    enter(ev) {
      ev.target.classList.add('hovered')
    },
    leave(ev) {
      ev.target.classList.remove('hovered')
    },
    play(index) {
      this.$audio.trackSeek(index)
    },
    remove(index) {
      this.$audio.dequeue(index)
    },
    reorder(ev) {
      if (ev.moved) {
        this.$audio.requeue({
          from: ev.moved.oldIndex,
          to: ev.moved.newIndex
        })
      }
    },
  }

}

</script>

<style lang="scss" scoped>
@import '/css/mixins';

.tracks {
  width: 100%;
  @include user-select-none;

  .track td {
    padding: 4px;
    @include user-select-none;

    &.index {
      color: #888;
    }

    &.title {
      width: 100%;
      cursor: pointer;
    }

    &.delete span {
      visibility: hidden;
      color: var(--danger-color) !important;
      font-size: 6pt;
      cursor: pointer;
    }

  }

  .track.active .title {
    color: var(--highlight-color);
  }

  .track.hovered .delete span {
    visibility: visible;
  }

}

</style>
